import React, {useEffect, useRef, useState} from 'react'
import {Link, useNavigate} from "react-router-dom"; // react-router
import {connect} from "react-redux";

// 资源

// 自定义组件
import {setUserinfo} from "../../redux/action";
import LoginPanel from "../../auth/login_panel";
import {Declaration, SubmitRequetst} from "./popup";
import {apis} from "../api";


// 重新封装Link组件
function Linkx(props){
	// console.log(window.location.host + "/topic/"+props.name)
	return(
		<Link to={"/topic/"+props.name} style={{textDecoration:"none"}}>
			{/*<div className='leadmiddlemenuli' onClick={() => {window.location.href = window.location.host + "/topic/"+props.name}}>{props.name}</div>*/}
			<div className='leadmiddlemenuli'>{props.name}</div>
		</Link>
	)
}


function Header(props){
	
	// state
	const [topiclist, settopiclist] = useState([])
	const [showlogin, setshowlogin] = useState(false)
	const [showmulu, setshowmulu] = useState(false)
	const [showuserpanel, setshowuserpanel] = useState(false)
	const [showsearch, setshowsearch] = useState(false)
	const [showhelp, setshowhelp] = useState(false)
	const [showrequest, setshowrequest] = useState(false)
	
	// ref(input的非受控组件方法)
	const searchRef = useRef(null)
	const searchInput = useRef(null)
	const muluRef = useRef(null)
	const userpanelRef = useRef(null)
	
	// react-route
	const navigate = useNavigate()
	
	// 监测鼠标在用户界面外点击
	useEffect(() => {
		if (!showuserpanel) {
			return;
		}
		
		function handleClick(event) {
			if (userpanelRef.current && !userpanelRef.current.contains(event.target)) {
				// console.log('ops元素外')
				setshowuserpanel(false)
			}
			// console.log('ops元素内')
		}
		// console.log('ops添加监听')
		window.addEventListener("mousedown", handleClick);
		return () => window.removeEventListener("mousedown", handleClick);
	}, [showuserpanel]);
	
	useEffect(() => {
		if (!showmulu) {
			return;
		}
		
		function handleClick(event) {
			if (muluRef.current && !muluRef.current.contains(event.target)) {
				setshowmulu(false)
			}
		}
		window.addEventListener("mousedown", handleClick);
		return () => window.removeEventListener("mousedown", handleClick);
	}, [showmulu]);
	
	useEffect(() => {
		if (!showsearch) {
			return;
		}
		function handleClick(event) {
			if (searchRef.current && !searchRef.current.contains(event.target)) {
				setshowsearch(false)
			}
		}
		window.addEventListener("mousedown", handleClick);
		return () => window.removeEventListener("mousedown", handleClick);
	}, [showsearch]);
	
	useEffect(()=> {
		
		apis('gettopiclist').then(
			(res) => {
				settopiclist(res)
			}).catch(
			(error) => {
				console.log("gettopiclist request failed!")
			}
		);
		}, [])
	
	
	// 处理点击搜索
	const handleSearchClick = () => {
		if (searchInput.current.value !== ''){
			navigate("/search?keyword="+searchInput.current.value)
		}
	}
	
	// 处理搜索框enter
	const handleEnterDown = (event) => {
		if (event.key === 'Enter') {
			handleSearchClick()
		}
	}
	
	// 登出
	const handleLogout = () => {
		// 删除cookie的token项
		document.cookie = 'token' + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
		// 设置userinfo为null
		props.setUserinfo({logged:false})
		// 重新加载页面
		// eslint-disable-next-line no-restricted-globals
		location.reload(true);
	}
	
	return(
		<div style={{minWidth: '1140px'}}>
			<div className='head'>
				<div className='headleft' style={showsearch ? {} : {flexGrow:1}}>
					<Link to="/" style={{textDecoration:"none"}}>
						<div className='headleftlogo'>
							<svg className="headleftsvg" style={{width:'40px'}} aria-hidden="true" viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
								<path fill="none" d="M0,0h40v40H0V0z"/>
								<g>
									<path d="M19.7,19.2L4.3,35.3c0,0,0,0,0,0c0.5,1.7,2.1,3,4,3c0.8,0,1.5-0.2,2.1-0.6l0,0l17.4-9.9L19.7,19.2z" fill="#EA4335"/>
									<path d="M35.3,16.4L35.3,16.4l-7.5-4.3l-8.4,7.4l8.5,8.3l7.5-4.2c1.3-0.7,2.2-2.1,2.2-3.6C37.5,18.5,36.6,17.1,35.3,16.4z" fill="#FBBC04"/>
									<path d="M4.3,4.7C4.2,5,4.2,5.4,4.2,5.8v28.5c0,0.4,0,0.7,0.1,1.1l16-15.7L4.3,4.7z" fill="#4285F4"/>
									<path d="M19.8,20l8-7.9L10.5,2.3C9.9,1.9,9.1,1.7,8.3,1.7c-1.9,0-3.6,1.3-4,3c0,0,0,0,0,0L19.8,20z" fill="#34A853"/>
								</g>
							</svg>
							<div className='headlefttitle'>MyPlay</div>
						</div>

					</Link>
					
					<div className='leadmiddle' ref={muluRef}>
						{showmulu &&
							<div className='leadmiddlemenu'>
								<div className='leadmiddlemenudiv'>
									<div className='leadmiddlemenuul'>
										{topiclist.map((topic, index)=>(
											<Linkx name={topic.name} key={index}/>
										))}
									</div>
								</div>
							</div>
						}
						<div className='leadmiddletext' onClick={() => setshowmulu(!showmulu)}>
							类别
							{showmulu ?
								<i className='material-icons leadmiddleicon'>keyboard_arrow_up</i>
								:
								<i className='material-icons leadmiddleicon'>keyboard_arrow_down</i>
							}
						</div>
					
					</div>
					{/*<div className='divide'/>*/}
					{/*<div className='leadmiddlesub'>*/}
					{/*	<Link to="/topic/最高得分/" style={{color:'inherit'}}><div className='leadmiddlesubitem'>高评分</div></Link>*/}
					{/*	<Link to="/topic/最多下载/" style={{color:'inherit'}}><div className='leadmiddlesubitem'>下载量</div></Link>*/}
					{/*	<Link to="/topic/最近更新/" style={{color:'inherit'}}><div className='leadmiddlesubitem'>新上线</div></Link>*/}
					{/*</div>*/}
				</div>
				
				{/*搜索*/}
				{showsearch &&
					<div className='headsearch' ref={searchRef}>
						<input placeholder='搜索' className='headsearchinput' onKeyDown={handleEnterDown} ref={searchInput}/>
						<i className='material-icons headsearchlogo' onClick={handleSearchClick}>search</i>
					</div>
				}
				
				<div className='headright' ref={userpanelRef}>
					<i className='material-icons headrightapp' onClick={()=>{setshowsearch(true)}}>search</i>
					<i className='material-icons headrightapp' onClick={()=>{setshowhelp(true)}}>help</i>
					
					{/*<div>未完待续</div>*/}
					{/*{props.userinfo.logged ? <img className='headrightavatar' src={icon} onClick={()=>{setuserpanel(!userpanel)}} alt=''/> : <div className='headrighttxt' onClick={handleLogin}>登录</div>}*/}
					{props.userinfo.logged ?
						<div className='headrightavatarname' style={{backgroundColor:props.userinfo.color}} onClick={()=>{setshowuserpanel(!showuserpanel)}}>{props.userinfo.username.slice(0,1)}</div>
						:
						<div className='headrighttxt' onClick={()=>{setshowlogin(true)}}>登录</div>
					}
					{showuserpanel &&
						<div className='usermain'>
							<div className='userinfo'>
		            <div className='userinfoavatartxt' style={{background: props.userinfo.color}}> {props.userinfo.username.slice(0, 1)} </div>
		            <div className='userinfodiv'>
		              <div className='userinfousername'>{props.userinfo.username}</div>
		              <div className='userinfoemail'>{props.userinfo.email}</div>
		            </div>
		          </div>
							<div className='usermenu'>
		            <div className='usermenuitem' onClick={()=>{navigate('/favorite')}}>
		              <i className='material-icons usermenuitemicon'>bookmark_added</i>
		              我的收藏
		            </div>
		            <div className='usermenuitem' onClick={()=>{setshowrequest(true)}}>
		              <i className='material-icons usermenuitemicon'>ballot</i>
		              我的请求
		            </div>
		            <div className='usermenuitem' onClick={handleLogout}>
		              <i className='material-icons usermenuitemicon'>logout</i>
		              退出账号
		            </div>
              </div>
							<div className='usermenutip'>隐私权政策·服务条款</div>
						</div>
					}
				</div>
				
			</div>
			
			{/*登录*/}
			{showlogin && <LoginPanel close={()=>{setshowlogin(false)}}/>}
			
			{/*帮助*/}
			{showhelp && <Declaration close={()=>{setshowhelp(false)}}/>}
			
			{/*请求*/}
			{showrequest && <SubmitRequetst close={()=>{setshowrequest(false)}}/>}
		</div>
	)
}


// export default Header;

const mapStateToProps = store => ({
	userinfo: store.app.userinfo,
});

const mapDispatchToProps = dispatch => ({
	setUserinfo: (userinfo) => dispatch(setUserinfo(userinfo)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);